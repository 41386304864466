import React, {
  createContext,
  useContext,
  useReducer,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {ThemeProvider, makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import {
  BrowserRouter,
  Switch,
  Route,
  NavLink,
  Redirect,
  useLocation,
} from 'react-router-dom';
import {
  config,
  useTransition,
  useChain,
  useTrail,
  useSpring,
  animated,
} from 'react-spring';
import {useTranslation, Trans} from 'react-i18next';
import theme from './theme';

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— MAIN

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ImgurContextProvider>
          <DataContextProvider>
            <Layout>
              <Switch>
                <Route path="/h" exact>
                  <Home />
                </Route>
                <Route path="/a" exact>
                  <About />
                </Route>
                <Route path="/c" exact>
                  <Contact />
                </Route>
                <Route path="/p" exact>
                  <Products />
                </Route>
                <Route path="/o" exact>
                  <Order />
                </Route>
                <Redirect to="/h" exact />
              </Switch>
            </Layout>
          </DataContextProvider>
        </ImgurContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const Layout = ({children}) => {
  const classes = useStyles();
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <main className={classes.main}>{children}</main>
      <Menu />
      <Nav />
    </div>
  );
};

//  ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— PAGES

const Home = () => {
  const classes = useStyles();
  const {home} = useData();

  if (!home || home.length === 0) return null;
  const gif = home?.[0];

  return (
    <Container className={classes.container} maxWidth="sm">
      <div className={classes.parent}>
        <video playsInline autoPlay muted loop className={classes.gif}>
          <source src={gif.link} type="video/mp4" />
        </video>
      </div>
    </Container>
  );
};

const About = () => {
  const classes = useStyles();
  const {about} = useData();

  if (!about || !(about?.[0] && about?.[1])) return null;

  return (
    <Container className={classes.container} maxWidth="sm">
      <Typography className={classes.text} variant="h6">
        <Trans
          i18nKey="about.text"
          components={{
            image1: <Img img={about[0]} className={classes.insert} />,
            image2: <Img img={about[1]} className={classes.insert} />,
          }}
        />
      </Typography>
    </Container>
  );
};

const Order = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {order} = useData();

  if (!order || !(order?.[0] && order?.[1])) return null;

  return (
    <Container className={classes.container} maxWidth="sm">
      <Typography className={classes.text} variant="h6">
        <Trans
          i18nKey="order.text"
          components={{
            image1: <Img className={classes.insert} img={order[0]} />,
            image2: <Img className={classes.insert} img={order[1]} />,
            tel: <Uri className={classes.link} url={`tel:${t('order.tel')}`} />,
            email: (
              <Uri
                className={classes.link}
                url={`mailto:${t('order.email')}`}
              />
            ),
          }}
        />
      </Typography>
    </Container>
  );
};

const Contact = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {contact} = useData();

  if (!contact || contact.length === 0) return null;
  const img = contact?.[0];

  return (
    <Container className={classes.container} maxWidth="sm">
      <div className={classes.parent}>
        <img className={classes.gif} alt={img.description} src={img.link} />
        <div className={classes.grid}>
          <div className={clsx(classes.item, classes.item1)}>
            <Typography className={classes.info} variant="h6">
              <Trans
                i18nKey="contact.address"
                components={{
                  address: (
                    <Uri
                      className={classes.link}
                      url={t('contact.googlemaps')}
                      target="_blank"
                    />
                  ),
                }}
              />
            </Typography>
          </div>
          <div className={clsx(classes.item, classes.item2)}>
            <Typography className={classes.info} variant="h6">
              <Trans
                i18nKey="contact.phone"
                components={{
                  tel: (
                    <Uri
                      className={classes.link}
                      url={`tel:${t('contact.tel')}`}
                    />
                  ),
                }}
              />
            </Typography>
          </div>
          <div className={clsx(classes.item, classes.item3)}>
            <Typography className={classes.info} variant="h6">
              <Trans
                i18nKey="contact.mail"
                components={{
                  email: (
                    <Uri
                      className={classes.link}
                      url={`mailto:${t('contact.email')}`}
                    />
                  ),
                }}
              />
            </Typography>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Products = () => {
  const classes = useStyles();
  const {products} = useData();

  if (!products) return null;

  return (
    <Container className={classes.container} maxWidth="sm">
      {products.map((album, i) => (
        <Product album={album} key={i} />
      ))}
    </Container>
  );
};

const Product = ({album}) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const images = album?.images || [];

  const onClick = useCallback(
    () => images.length > 0 && setIndex(s => (s + 1) % images.length),
    [images]
  );

  const transitions = useTransition(index, p => p, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
    trail: 50,
  });

  if (!images || images.length === 0) return null;
  const lines = images[index].description.split(/(?:\r\n|\r|\n)/g);

  return (
    <div className={classes.product}>
      <span>
        <Typography className={classes.caption} variant="h6">
          {album.title}
        </Typography>
      </span>
      <div className={classes.slide} onClick={onClick}>
        {transitions.map(({item, key, props}) => (
          <animated.div key={key} style={props}>
            <img alt={images[item].description} src={images[item].link} />
          </animated.div>
        ))}
        <Typography component="span" variant="h6">
          {`${index + 1} / ${images.length}`}
        </Typography>
      </div>
      <span>
        {lines.map((l, i) => (
          <Typography className={classes.caption} variant="h6" key={i}>
            {l}
          </Typography>
        ))}
      </span>
    </div>
  );
};

const Img = ({img, className}) => (
  <img className={className} alt={img.description} src={img.link} />
);

const Uri = ({url, className, target = '_self', children}) => (
  <Link className={className} variant="h6" href={url} target={target}>
    {children}
  </Link>
);

//  ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— NAV

const Nav = () => {
  const classes = useStyles();

  return (
    <div className={classes.nav}>
      <div className={classes.left}>
        <Hours />
      </div>
      <div className={classes.right}>
        <Hamburger />
      </div>
    </div>
  );
};

const Menu = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {menu, setMenu} = useData();

  const items = [
    {label: t('menu.home'), route: '/h'},
    {label: t('menu.about'), route: '/a'},
    {label: t('menu.contact'), route: '/c'},
    {label: t('menu.products'), route: '/p'},
    {label: t('menu.order'), route: '/o'},
    {label: t('menu.fb'), url: t('social.fb')},
    {label: t('menu.insta'), url: t('social.insta')},
  ];

  const springRef = useRef();
  const spring = useSpring({
    ref: springRef,
    transform: menu ? 'translateY(0%)' : 'translateY(-100%)',
  });

  const trailRef = useRef();
  const trail = useTrail(items.length, {
    ref: trailRef,
    opacity: menu ? 1 : 0,
    p: menu ? 0 : 30,
    from: {opacity: 0, p: 30},
    config: config.stiff,
  });

  useChain(menu ? [springRef, trailRef] : [trailRef, springRef], [0, 0.4]);

  return (
    <animated.div className={classes.menu} style={spring}>
      <Box px={2} py={6} letterSpacing={10} textAlign="center" component="nav">
        {trail.map(({p, ...rest}, i) => (
          <animated.div
            key={i}
            className={classes.trail}
            style={{
              ...rest,
              transform: p.interpolate(p => `translate3d(${p}%,0,0)`),
            }}
          >
            {!!items[i].route && (
              <Typography
                variant="h6"
                className={classes.link}
                component={NavLink}
                to={items[i].route}
                activeClassName={classes.active}
                onClick={() => setMenu(false)}
              >
                {items[i].label}
              </Typography>
            )}
            {!!items[i].url && (
              <Link
                variant="h6"
                className={classes.link}
                target="_blank"
                href={items[i].url}
              >
                {items[i].label}
              </Link>
            )}
          </animated.div>
        ))}
      </Box>
    </animated.div>
  );
};

const Hours = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.hours}>
      <div>
        <Typography>
          {t('hours.monday')}
          <span>—</span>
          {t('hours.friday')}
        </Typography>
        <Typography>
          {t('hours.saturday')}
          <span>——</span>
        </Typography>
        <Typography>
          {t('hours.sunday')}
          <span>——</span>
        </Typography>
      </div>
      <div>
        <Typography>{t('hours.start1')}</Typography>
        <Typography>{t('hours.start2')}</Typography>
        <Typography>{t('hours.start3')}</Typography>
      </div>
      <div>
        <Typography>&nbsp;—&nbsp;</Typography>
        <Typography>&nbsp;—&nbsp;</Typography>
        <Typography>&nbsp;—&nbsp;</Typography>
      </div>
      <div>
        <Typography>{t('hours.end1')}</Typography>
        <Typography>{t('hours.end2')}</Typography>
        <Typography>{t('hours.end3')}</Typography>
      </div>
    </div>
  );
};

const Hamburger = () => {
  const classes = useStyles();
  const {menu, setMenu} = useData();

  const trans = (p, r) => `translateY(${p}px) rotateZ(${r}deg)`;
  const p1 = menu ? 0 : -24;
  const r1 = menu ? -45 : 0;
  const p2 = menu ? 0 : 24;
  const r2 = menu ? 45 : 0;

  const prop1 = useSpring({opacity: menu ? 0 : 1});
  const prop2 = useSpring({transform: trans(p1, r1)});
  const prop3 = useSpring({transform: trans(p2, r2)});

  return (
    <div className={classes.hamburger} onClick={setMenu}>
      <animated.div style={prop1} />
      <animated.div style={prop2} />
      <animated.div style={prop3} />
    </div>
  );
};

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— STYLES

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: '320px',
    animation: '$fadein ease 2s',
  },
  '@keyframes fadein': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  main: {
    paddingTop: theme.mixins.toolbar.minHeight,
  },
  nav: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: theme.mixins.toolbar.minHeight,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `3px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.background.default,
  },
  menu: {
    position: 'fixed',
    top: theme.mixins.toolbar.minHeight,
    width: '100%',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    overflowX: 'hidden',
    overflowY: 'scroll',
    willChange: 'transform',
    borderBottom: `3px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.background.default,
    '& > * > *': {
      padding: theme.spacing(3),
    },
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      borderBottom: `3px solid ${theme.palette.text.primary}`,
    },
  },
  active: {
    borderBottom: `3px solid ${theme.palette.text.primary}`,
  },
  left: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  right: {
    padding: theme.spacing(0, 2),
  },
  hours: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '2px',
    '& span': {
      margin: theme.spacing(0, 1),
      letterSpacing: -2,
    },
    '& > :first-child': {
      marginRight: theme.spacing(2),
    },
  },
  hamburger: {
    position: 'relative',
    flexDirection: 'row',
    width: 0.7 * theme.mixins.toolbar.minHeight,
    height: '100%',
    userSelect: 'none',
    cursor: 'pointer',
    '& > *': {
      position: 'absolute',
      top: '50%',
      left: '0',
      width: '100%',
      marginTop: -2,
      height: 4,
      borderRadius: 2,
      backgroundColor: theme.palette.text.primary,
    },
  },
  trail: {
    willChange: 'transform, opacity',
  },
  container: {
    padding: theme.spacing(4, 2),
  },
  gif: {
    display: 'block',
    width: '100%',
    userSelect: 'none',
  },
  insert: {
    display: 'block',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2, 4),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 16),
    },
    userSelect: 'none',
  },
  text: {
    textAlign: 'center',
    lineHeight: '200%',
    letterSpacing: '6px',
  },
  parent: {
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(0, 0),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  grid: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    position: 'absolute',
    width: '1px',
    height: '1px',
  },
  item1: {
    top: '5%',
    left: '66%',
  },
  item2: {
    top: '35%',
    left: '35%',
  },
  item3: {
    top: '70%',
    left: '45%',
  },
  info: {
    textAlign: 'center',
    lineHeight: '150%',
    letterSpacing: '6px',
  },
  product: {
    position: 'relative',
    width: '100%',
    border: `3px solid ${theme.palette.text.primary}`,
    borderRadius: 2,
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      padding: theme.spacing(2),
    },
  },
  slide: {
    position: 'relative',
    width: '100%',
    paddingTop: '66.7%',
    overflow: 'hidden',
    cursor: 'pointer',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      willChange: 'opacity',
    },
    '& > div >  img': {
      display: 'block',
      width: '100%',
      userSelect: 'none',
    },
    '& > span': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      textAlign: 'center',
      color: 'white',
      padding: theme.spacing(),
      userSelect: 'none',
    },
  },
  caption: {
    textAlign: 'center',
  },
}));

export default App;

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— UTILS

const fetchAPI = async (url, params) => {
  let {token, body = null, headers = {}, isJSON = true, ...options} = params;
  options = {
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  };
  if (token)
    options.headers = {...options.headers, Authorization: `Bearer ${token}`};
  if (body) options.body = isJSON ? JSON.stringify(body) : body;
  if (!isJSON) delete options.headers['Content-Type'];
  try {
    const response = await fetch(url, options);
    return await response.json();
  } catch (error) {
    throw error.message ?? error.error;
  }
};

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— API

const ImgurContext = createContext();
const useImgur = () => useContext(ImgurContext);
const ImgurContextProvider = ({children}) => {
  const find = async album => {
    try {
      const response = await fetchAPI(
        `https://api.imgur.com/3/album/${album}`,
        {
          headers: {
            Authorization: `Client-ID ${process.env.REACT_APP_IMGUR_ID}`,
          },
        }
      );
      if (response.data.hasOwnProperty('error')) throw response;
      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  return (
    <ImgurContext.Provider value={{find}}>{children}</ImgurContext.Provider>
  );
};

// ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————— STORE

const DataContext = createContext();
const useData = () => useContext(DataContext);
const DataContextProvider = ({children}) => {
  const {t} = useTranslation();
  const {find} = useImgur();
  const [menu, setMenu] = useReducer(i => !i, false);
  const [home, setHome] = useState();
  const [about, setAbout] = useState();
  const [contact, setContact] = useState();
  const [order, setOrder] = useState();
  const [products, setProducts] = useState();

  useEffect(() => {
    const getImgurDatas = async () => {
      const home = await find(t('imgur.home'));
      setHome(home?.images);
      const about = await find(t('imgur.about'));
      setAbout(about?.images);
      const contact = await find(t('imgur.contact'));
      setContact(contact?.images);
      const order = await find(t('imgur.order'));
      setOrder(order?.images);
      const galleries = t('imgur.products', {returnObjects: true});
      const products = await Promise.all(
        galleries.map(async gallery => await find(t(gallery)))
      );
      setProducts(products);
    };
    getImgurDatas();
  }, []); // eslint-disable-line

  return (
    <DataContext.Provider
      value={{menu, setMenu, home, about, contact, order, products}}
    >
      {children}
    </DataContext.Provider>
  );
};
