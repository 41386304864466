import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import AntarcticaBetaWoff from './fonts/AntarcticaBeta-ExtraCondMed.woff';
import AntarcticaBetaWoff2 from './fonts/AntarcticaBeta-ExtraCondMed.woff2';
import AntarcticaBetaTtf from './fonts/AntarcticaBeta-ExtraCondMed.ttf';

const antartica = {
  fontFamily: 'Antarctica Beta',
  src: `url(${AntarcticaBetaWoff}) format('woff'), url(${AntarcticaBetaWoff2}) format('woff2'), url(${AntarcticaBetaTtf}) format('truetype')`,
  fontWeight: 500,
  fontStyle: 'normal',
  fontDisplay: 'swap',
};

const light = {
  type: 'light',
  background: {
    default: '#fbf6df',
  },
  text: {
    primary: '#eb6608',
  },
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Antarctica Beta, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 15,
  },
  palette: light,
  mixins: {
    toolbar: {
      minHeight: 96,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [antartica],
      },
    },
  },
});

export default responsiveFontSizes(theme);
